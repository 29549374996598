.authButton {
  margin-top: 30px;
  text-align: center;
  border-radius: 25px;
  width: 90vw;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  border-color: #000000;
}
.authText {
  color: #000000;
  font-size: 20px;
  padding-top: 13px;
  padding-bottom: 13px;
}

.createButton {
  margin-top: 15vw;
  text-align: center;
  width: 160px;
  height: 160px;
  border-radius: 80px;
  margin-left: auto;
  margin-right: auto;
  border-color: #000000;
  padding-top: 18px;
}

.authenticateModal {
  --border-radius: 10px;
}

.shareplayHelpIcon {
  color: #99fdff;
  font-size: 28px;
}

.shareplayHelpButton {
}

.joinButton {
  margin-top: 10vh;
  text-align: center;
  border-radius: 25px;
  padding-top: 1px;
  padding-bottom: 5px;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  border-color: #000000;
}

a {
  text-decoration: none;
}

.shareplayBackground {
  --background: #222222;
}

.modalBackground {
  --background: #222222;
  --border-radius: 10px;
}

.profileModal {

}

.shareplayToolbar {
  --background: #111111;
  color: #ffffff;
  border-bottom: .5px solid #eeeeee;
}

.joinedUsers {
  height: 150px;
  background-color: #222222;
}

.authDesc {
  color: #cae3e3;
  text-align: center;
  width: 80vw;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  margin-top: 25vh;
}

.profileIcon {
  color: #ffffff;
  font-size: 32px
}

.menuIcon {
  color: #ffffff;
  font-size: 32px
}
.leaveShareplay {
  color: #ffffff;
  font-size: 32px;
}

.joinCodeInput {
  --background: #222222;
  font-size: 18px;
  width: 80vw;
  --padding-start: 10px;
  color: #ffffff;
  caret-color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-transform: uppercase;
}

.joinCodeLabel {
  color: #99fdff;
  margin-left: 15px;
  margin-right: 5px;
  margin-top: auto;
  margin-bottom: auto;
}


.joinCodeParent {
  display: flex;
  flex-direction: row;
  background-color: #222222;
  width: 70vw;
  max-width: 600px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid white;
  height: 80px;
}

.joinIcon {
  color: #ffffff;
  font-size: 33px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
}

.joinLabel {
  color: #99fdff;
  margin-left: 20px;
  font-size: 22px;
}

.joinParent {
  margin-top: 40px;
}

.createLabel {
  color: #99fdff;
  margin-left: 20px;
  font-size: 22px;
}

.createParent {
  margin-top: 80px;
}

.createButtonParent {
  display: flex;
  flex-direction: row;
  width: 70vw;
  height: 80px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  border: 1px solid white;
}

.shareplayToolbar {
  --background: #222222;
}

.createText {
  color: #99fdff;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 19px;
}

.shareplayTitle {
  --color: #fff;
  --background: #ffffff;
}

.profToolbar {
  --background: #222222;
  border-bottom: 1px solid #eeeeee;
}
.profClose {
  --color: #99fdff;
  color: #99fdff;
}

.profLogOut {
  --color: #ff5c84;
  color: #ff5c84;
}
.profPagePhoto {
  margin-left: auto;
  margin-right: auto;
  height: 150px;
  width: 150px;
  margin-top: 15px;
}

.profPageTitle {
  color: #ffffff;
  text-align: center;
  font-size: 24px;
}

.usernameItemAlt {
  --color: #99fdff;
  --background: transparent;
  background-color: #2f2f2f;
  border: 1px solid white;
  border-radius: 7px;
  width: 80vw;
  margin-left: 15px;
  padding-top: 5px;

}

.usernameItem {
  --color: #99fdff;
  --background: transparent;
  background-color: #4f4f4f;
  border: 1px solid white;
  border-radius: 7px;
  width: 80vw;
  margin-left: 20px;
  padding-top: 5px;
}

.usernameLabel {
  color: #99fdff;
  margin-left: 20px;
}

.usernameButton {
  --color: #99fdff;
  margin-right: 10px;
}
.usernameFloat {
  display: flex;

}
.usernameContainer {

}
.usernameIcon {

}

.usernameInput {
}

#shareplayMenuContent {
  --background: #3f3f3f;
}

.shareplayMenuItem {
  --background: #3f3f3f;
  color: #ffffff;
}

.shareplayMenuLeave {
  color: #ff0000;
  --background: #3f3f3f;
}

.numMembersBadge {
  --background: #99fdff;
  --color: #000;
}
