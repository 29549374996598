.toggleFields {
  width: 90vw;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  max-width: 600px;
}

.loginHeader {
  float: left;
}

.registerHeader {
  float: right;
}

.appLogo {
  text-align: center;
  height: 90px;
  margin-top: 40px;
}

.appLogoText {
  color: #99fdff;
  font-size: 52px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 1000px;
  margin-bottom: -15px;
}

.appLogoBottomText {
  color: #cae3e3;
}

.landingBackground {
  --background: #222222;
  position: relative;
}

.fieldsContainer {
  margin-top: 40px;
}

.emailContainer {
  background-color: #222222;
  border: 1px solid white;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  border-radius: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  max-width: 600px;
}

.passwordContainer {
  background-color: #222222;
  border: 1px solid white;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  border-radius: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  max-width: 600px;
}

.passwordItem {
  --background: transparent;
  --placeholder-color: #ffffff;
  --color: #ffffff;
}

.usernameContainerLanding {
  background-color: #222222;
  border: 1px solid white;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  border-radius: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 600px;
}

.inputField {
  --placeholder-color: #ffffff;
  --color: #ffffff;
  --padding-start: 4%;
}

.usernameItemLanding {
  --background: transparent;
  --placeholder-color: #ffffff;
  --color: #ffffff;
}

.usernameIconLanding {
  color: #99fdff;
}

.emailItem {
  --background: transparent;
  --placeholder-color: #ffffff;
}

.submitButton {
  margin-top: 30px;
  text-align: center;
  border-radius: 25px;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  border-color: #000000;
  max-width: 400px;
  display: flex;
  color: #000000;
  font-size: 20px;
  --padding-top: 0px;
}



.helpIcon {
  color: #99fdff;
}
