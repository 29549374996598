
.memberBox {
  --color: #ffffff;
  --background: transparent;
  border-bottom: 1px solid white;
  background-color: #222222;
  padding-bottom: 5px;
  padding-top: 5px;
}

.memberToolbarBackground {
  --background: #222222;
}

.memberContentBackground {
  --background: #222222;
}

.memberTitle {
  color: #99fdff;
}

.memberClose {
    --color: #ff5c84;
}
.profPicture {

}

.profName {
  --color: #ffffff;
}
