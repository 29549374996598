.createBackground {
  height: 100%;
  width: 100%;
  background-color: #222222;
}

.joinedUserHeader {
  color: #99fdff;
  padding-left: 20px;
}

.joinedUserList {
  height: 200px;
  background-color: #444444;
}

.buttonsParent {
  display: flex;
  width: 100vw;
}

.syncPlay {
  width: 50%;
  text-align: center;
  border-bottom: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}

.syncPause {
  width: 50%;
  border-bottom: 1px solid #eeeeee;
  text-align: center;
}

.syncPlayLabel {
  color: #99fdff;
  font-size: 21px;
}

.syncPauseLabel {
  color: #99fdff;
  font-size: 21px;
}

.ownerIcon {
  color: #99fdff;
}

.playText {
  color: #ffffff;
  padding-top: 13px;
  padding-bottom: 13px;
}

.pauseText {
  padding-top: 13px;
  padding-bottom: 13px;
  color: #ffffff;
}

.codeParent {
  width: 100%;
  border-top: 1px solid #fff;
  position: fixed;
  bottom: 0;
  height: 70px;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #2f2f2f;
}

.shareplayCode {
  color: #ffffff;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 10px;
  border: 1px solid #99fdff;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #222222;
}

.membersParent {
  margin-top: 10px;
  background-color: #000000;
}

.profPicture {

}

.profName {
  --color: #ffffff;
}

.noContent {
  color: #ffffff;
  text-align: center;
  margin-top: 35vh;
}

.memberBox {
  --color: #ffffff;
  --background: transparent;
  border-bottom: 1px solid white;
  background-color: #222222;
  padding-bottom: 5px;
  padding-top: 5px;
}

.memberToolbarBackground {
  --background: #222222;
}

.memberContentBackground {
  --background: #222222;
}

.memberTitle {
  color: #99fdff;
}

.memberClose {
    --color: #ff5c84;
}

.albumArt {
  width: 75vw;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 12vh;
  max-width: 400px;
}

.songText {
  color: #fff;
  text-align: center;
  font-size: 22px;
}

.artistText {
  color: #fff;
  text-align: center;
  font-size: 18px;
}

.helpRow {
  display: flex;
  width: 90vw;
  max-width: 570px;
}

.helpModal {
  --background: #222222;
}

.helpText {
  margin-left: 30px;
  color: #fff;
  font-size: 22px;
}

.helpAlert {
  color: #99fdff;
  font-size: 26px;
  margin-left: 5px;
}

.helpDismiss {
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  max-width: 300px;
  --background: #99fdff;
  color: #222222;
}

.dismissContainer {
  width: 100%;
  display: flex;
}

.copiedToast {
  --background: #222222;
  color: #99fdff;
  --border-color: #ffffff;
  --border-style: solid;
  --border-width: 1px;
  text-align: center;
}
